
body {
	background: $background-principle;
}
.title {
	&--second {
		color: $title--second;
	}
	&--principal {
		color: $title--principal;
	}
}

.descriptor {
	color: $descriptor;
}

.button {
	color: $button-color;

	
	&[disabled="disabled"] {
		cursor: auto;
	}
	&--default {
		background-color: $button-default-bg;
		color: $button-default-color;

		&:hover {
			background-color: $button-default-bg--hover;
		}
		&:active {
			background-color: $button-default-bg--active;
		}

		&[disabled="disabled"] {
			background: $button-default-bg;
			&:hover, &:focus, &:active {
				background: $button-default-bg;
			}
		}
	}

	&--thin {
		background-color: $button-thin-bg;

		&:hover {
			background-color: $button-thin-bg--hover;
		}
		&:active {
			background-color: $button-thin-bg--active;
		}

		&[disabled="disabled"] {
			background: $button-thin-bg;
			&:hover, &:focus, &:active {
				background: $button-thin-bg;
			}
		}
	}

	&--circle {
		background-color: $button-circle-bg;
		box-shadow: 0 0 0 2px $button-circle-border-color;

		&:hover {
			background-color: $button-circle-bg--hover;
		}
		&:active {
			background-color: $button-circle-bg--active;
		}
	}

	&--play {
		background-color: $button-play-bg;
	}
}

.switch-lang {
	&--open {
		background:  $button-circle-bg--hover;
		.button {
			background: $button-circle-bg--active;
		}
	}
}
.lang-switcher {
	background: $lang-switcher-background;
	&:before {
		background: $lang-switcher-background;
	}

	&__link {
		color: $lang-switcher-link-color;

		&--active, &:hover {
			color: $lang-switcher-link-hover;
		}
	}
}

.sites {
	&__text {
		color: $sites-color;
	}
}

.news {
	&-block {
		background: $news-block-background;

		&__title {
			color: $news-title;
		}

		&__text {
			color: $news-text;
		}
	}
}

.footer {
	background-color: $footer-bg;
}

.subscribe-form {
	&__label {
		color: $subscribe-form-label;
	}
	&__input {
		box-shadow: inset 0 0 0 2px $subscribe-form-input-shadow;
		background-color: $subscribe-form-input-background;
		color: $subscribe-form-input-color;

		&::placeholder {
			color: $subscribe-form-input-placeholder-color;
		}
	}
}

.copyright {
	color: $copyright-text-color;

	&__link {
		color: $copyright-link-color;
	}
}

.social {
	&__network {
		&-link {
			color: $social-link-color;
		}
	}
}

.mobile-menu {
	background: $mobile-menu-background;

	&__link {
		color: $mobile-menu__link-color;
	}
}

.nav {
	&__link {
		color: #93AAC1;
	}
}

.burger {
	&__stick {
		background: $burger-background;
	}
}

.header {
	background: $background-principle;

	&__logo {
		&-text {
			color: $header__logo-text;
		}
	}
}

.weStarted {
	&__descriptor {
		color: $weStarted__descriptor-color;
	}
}

.graphics {
	background-image: url($weStarted__cloud--big);

	&__rocket {
		background-image: url($weStarted__rocket);
	}

	&__cloud {
		&--left {
			background-image: url($weStarted__cloud--left); 
		}

		&--right {
			background-image: url($weStarted__cloud--right);
		}
	}
}

.record-table {
	.row {
		color: $recordTable__row-color;

		.record-table {
			&__place {
				&-text {
					color: $recordTable__place-text-color;
				}

				&--1 {
					background: $recordTable__place-1-bg;
				}

				&--2 {
					background: $recordTable__place-3-bg;
				}

				&--3 {
					background: $recordTable__place-3-bg;
				}
			}
		}
 
		&-1 {
			color: $recordTable__row-1-color;
			background-color: $recordTable__row-1-bg;
		}

		&-2 {
			background-color: $recordTable__row-2-bg;
		}

		&-3 {
			background-color: $recordTable__row-3-bg;
		}

		&-4 {
			background-color: $recordTable__row-4-bg;
		}

		&-2, &-3, &-4 {
			color: $recordTable__owner-color;
		}
	}
}

.video {
	&__container {
		background: $video__container--bg;
	}

	&__text {
		color: $video__text-color;
	}
}


.burger {
	&__stick {
		background: $burger__stick-bg;
	}
}

.form {
	&__text {
		color: $form__text-color;
	}

	&__field {
		color: $form__field-color;
		background: $form__field-bg;
		box-shadow: inset 0 0 0 2px $form__field-box-shadow-color;

		&::placeholder {
			color: $form__placeholder-color;
		}
		
		&--text {
			margin-bottom: 40px;
		}
	}
}

.popup {
	&__layer {
		background: $popup__layer-bg;
	}

	&__content {
		background: $popup__content-bg;
	}

	&__text {
		color: $popup__text;
	}

}