$white                                 : #ffffff;
$title--second                         : $white;
$title--principal                      : $white;
$descriptor                            : #93AAC1;

$background-principle                  : #2a3d50;
$news-block-background                 : #334A60;

$news-title                            : $white;
$news-text                             : #93AAC1;

$sites-color                           : $white;

$button-color                          : $white;
$button-play-bg                        : #1D8EDF;
$button-default-color                  : $white;
$button-default-bg                     : #1D8EDF;
$button-default-bg--hover              : #39A1ED;
$button-default-bg--active             : #1580CD;
$button-thin-bg                        : #3B5269;
$button-thin-bg--hover                 : #4D667F;
$button-thin-bg--active                : #3B5269;
$button-circle-bg                      : #2A3D50;
$button-circle-bg--hover               : #3B5269;
$button-circle-bg--active              : #3B5269;
$button-circle-border-color            : $button-circle-bg--hover;
$lang-switcher-background              : #3B5269;
$lang-switcher-link-color              : #93AAC1;
$lang-switcher-link-hover              : $white;

$footer-bg                             : #192735;

$subscribe-form-label                  : $white; 
$subscribe-form-input-shadow           : #3B5269;
$subscribe-form-input-background       : #192735;
$subscribe-form-input-color            : $white;
$subscribe-form-input-placeholder-color: #7B90A5;

$copyright-text-color                  : #93AAC1;
$copyright-link-color                  : #93AAC1;

$social-link-color                     : #93AAC1;

$mobile-menu-background                : #2a3d50;
$mobile-menu__link-color               : $white;

$burger-background                     : $white;
$header-bg                             : #2a3d50;
$header__logo-text                     : $white;

$weStarted__cloud--big                 : "../../img/weStarted/principal_cloud_black.svg";
$weStarted__rocket                     : "../../img/weStarted/rocket--dark.svg";
$weStarted__cloud--left                : "../../img/weStarted/cloud__left--dark.svg";
$weStarted__cloud--right               : "../../img/weStarted/cloud__right--dark.svg";
$weStarted__descriptor-color           : #93AAC1;

$recordTable__row-color                : $white;
$recordTable__place-text-color         : $white;
$recordTable__place-1-bg               : #F5CB02;
$recordTable__place-3-bg               : #ADC2D5;
$recordTable__place-3-bg               : #E48F59;
$recordTable__row-1-bg                 : #253646;
$recordTable__row-2-bg                 : #334A60;
$recordTable__row-3-bg                 : rgba(#F6BF23, .1);
$recordTable__row-4-bg                 : #334A60;
$recordTable__row-1-color              : $white;
$recordTable__owner-color              : $white;

$video__container--bg                  : #192735;
$video__text-color                     : $white;

$burger__stick-bg                      : $white;

$form__text-color                      : #93AAC1;
$form__field-color                     : $white;
$form__field-bg                        : #2A3D50;
$form__field-box-shadow-color          : #3B5269;
$form__placeholder-color               : #7B90A5;

$popup__layer-bg                       : #141f2a;
$popup__content-bg                     : #2A3D50;
$popup__text                           : $white;

@import "./blocks/colorScheme";